import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { processTagApiModels, TagModel } from '@core/models';
import { InitialSettingsApiActions } from '@core/store/actions';
import { GetStartedPageActions } from '@core/store/feed/actions';


export interface State extends EntityState<TagModel> {
    isLoaded: boolean;
    showOnlyPopular: boolean;
    uris: { [key: string]: number } | null;
}

export const adapter: EntityAdapter<TagModel> = createEntityAdapter<TagModel>({
    sortComparer: (first, second): number => {
        const firstName = first.name.toLowerCase();
        const secondName = second.name.toLowerCase();
        return firstName.localeCompare(secondName);
    }
});

export const initialState: State = adapter.getInitialState({
    uris: null,
    isLoaded: false,
    showOnlyPopular: true,
    searchCriteria: ''
});


const TagsReducer = createReducer(
    initialState,
    on(InitialSettingsApiActions.loadSucceeded, (state: State, {tags}): State => {
        const processed = processTagApiModels(tags);
        const uris = {};
        processed.forEach((tag: TagModel): void => {
            uris[tag.uri] = tag.id;
        });
        return adapter.setAll(processed, {
            ...state,
            uris,
            isLoaded: true
        });
    }),
    on(
        GetStartedPageActions.showPopularButtonToggled,
        (state): State => ({
            ...state,
            showOnlyPopular: !state.showOnlyPopular
        })
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return TagsReducer(state, action);
}

export const {
    selectAll: getTags,
    selectEntities: getTagsEntities,
} = adapter.getSelectors();

export const getShowOnlyPopular = (state: State) => state.showOnlyPopular;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getTagsUris = (state: State) => state.uris;


