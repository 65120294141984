import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CustomSvgIcon } from '@material/custom-svg-icon';
import { environment } from 'src/environments/environment';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { WINDOW } from '@core/window';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends WithDestroyedSubjectComponent implements OnInit, OnDestroy {
    readonly customSvgIcons = CustomSvgIcon;
    readonly blogUrl = environment.blogUrl;
    readonly facebookUrl = environment.facebookUrl;
    readonly linkedInUrl = environment.linkedInUrl;
    readonly instagramUrl = environment.instagramUrl;
    readonly youtubeUrl = environment.youtubeUrl;
    readonly redditUrl = environment.redditUrl;
    readonly mediumUrl = environment.mediumUrl;

    @HostBinding('class.hidden')
    isHidden = false;

    @HostBinding('class.sticky')
    isSticky = true;

    date: Date;

    timer: ReturnType<typeof setTimeout>;

    @Input()
    addRelNofollowAttr = true;

    @Input()
    sticky = true;

    constructor(
        public router: Router,
        public matDialog: MatDialog,
        @Optional() @Inject(WINDOW) private window: Window | null
    ) {
        super();
        this.date = new Date();
    }

    onWindowScroll() {
        clearTimeout(this.timer);
        this.isHidden = true;
        this.timer = setTimeout(() => this.isHidden = false, 2000);
    }

    ngOnInit(): void {
        if(this.sticky === false) {
            this.isSticky = false;
            return;
        }
        this.onWindowScroll = this.onWindowScroll.bind(this);
        if (this.window) {
            window.addEventListener('scroll', this.onWindowScroll, true);
        }
    }

    ngOnDestroy() {
        if (this.window) {
            window.removeEventListener('scroll', this.onWindowScroll, true);
        }
    }
}
