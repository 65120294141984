<header class="app-header">
    <mat-menu #appMenu="matMenu" class="main-menu">
        <a mat-menu-item [href]="readingRoomUrl" *appFeatureFlag="'blog'">Reading Room</a>
        <a mat-menu-item [href]="blogUrl" *appFeatureFlag="'blog'">Blog Page</a>
        <a mat-menu-item routerLink="/subject-page">Subject Page</a>
        <a mat-menu-item routerLink="/search-subject">Search by Subject</a>
        <a *ngIf="!!user" mat-menu-item routerLink="/users/search">Search by Member Name</a>
        <a mat-menu-item routerLink="/rapid-resources">Rapid Resources</a>
        <a *appFeatureFlag="'care-librarian'" mat-menu-item routerLink="/care-librarian">Care Librarian</a>
        <a mat-menu-item routerLink="/affirmation">Affirmations</a>
        <a mat-menu-item [matMenuTriggerFor]="marketplaces">Marketplaces</a>
        <mat-menu #marketplaces="matMenu" class="main-menu">
            <a mat-menu-item routerLink="/marketplace/caregiver-products-and-services">Caregiver Products and Services</a>
            <a mat-menu-item routerLink="/marketplace/business-products-and-services">Business Products and Services</a>
            <a mat-menu-item routerLink="/marketplace/home-healthcare-services">Home Healthcare Services</a>
            <a mat-menu-item routerLink="/marketplace/retirement-and-care-residences">Retirement and Care Residences</a>
        </mat-menu>

        <a mat-menu-item routerLink="/help-desk">Contact Us</a>
        <a  *appFeatureFlag="'tell-friend'"
            mat-menu-item
            appGaTrackEvent
            [gaEventCategory]="'share-with-friend'"
            [gaEvent]="'click'"
            routerLink="/invite-friend"
        >Invite a Friend</a>
        <a mat-menu-item routerLink="/invite-your-team">Invite your Care Team</a>
        <a mat-menu-item routerLink="/about">About Us</a>
    </mat-menu>
    <nav class="header-nav"
         [ngClass]="{
            'authenticated': !!user,
            'with-page-label': isFeedPage || pageLabel
         }"
         @voidAnimation
    >
        <div class="part left">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="appMenu" class="btn-main-menu">
                menu
            </button>
        </div>
        <div class="part center page-label-container" [class.feed-tag-name]="isFeedPage">
            <ng-container *ngIf="isFeedPage || pageLabel else logoElement">
                <h2 class="tag-name">{{(selectedTagData$ | async)?.root.name}}</h2>
                <span class="page-label" *ngIf="pageLabel" [innerHTML]="pageLabel"></span>
            </ng-container>
            <ng-template #logoElement>
                <a [routerLink]="['/']">
                    <mat-icon id="logo" [svgIcon]="customSvgIcons.logoFull"></mat-icon>
                </a>
            </ng-template>
            <ng-container *ngIf="showHeaderContestLink">
                <app-contest-link
                    *appFeatureFlag="'video-contest'"
                    [isFullTextAllowed]="isLongContestLinkAllowed"
                ></app-contest-link>
            </ng-container>
        </div>
        <app-resources *ngIf="isFeedPage"></app-resources>
        <div class="part right">
            <ng-container *ngIf="user; else buttonsNotAuthorized">
                <mat-menu #appProfileMenu="matMenu" class="profile-menu">
                    <a mat-menu-item class="profile-menu-user-info" [routerLink]="['/me/edit']">
                        <app-user-avatar
                            [user]="user"
                            [hideOnlineIndicator]="true"
                        ></app-user-avatar>
                        <div class="user-info">
                            <div class="username">{{user.username}}</div>
                            <div class="text">Edit my profile</div>
                        </div>
                    </a>
                    <a mat-menu-item
                       [routerLink]="['/me/conversations']"
                    >
                        <mat-icon>forum</mat-icon>
                        My private messages
                    </a>
                    <a mat-menu-item
                       [routerLink]="['/me']"
                    >
                        <mat-icon [svgIcon]="customSvgIcons.user"></mat-icon>
                        View my profile
                    </a>
                    <button
                        class="without-icon"
                        mat-menu-item
                        (click)="logout()"
                    >Logout
                    </button>
                </mat-menu>
                <a
                    mat-icon-button
                    aria-label="private chats"
                    color="primary"
                    [disableRipple]="true"
                    [routerLink]="['/me/conversations']"
                >
                    <mat-icon
                        [matBadgeHidden]="(unreadConversationsCount$ | async) === 0"
                        [matBadge]="(unreadConversationsCount$ | async)?.toString()"
                        matBadgeColor="accent"
                    >forum
                    </mat-icon>
                </a>
                <div
                    id="avatar-wrapper"
                    [matMenuTriggerFor]="appProfileMenu"
                >
                    <app-user-avatar
                        [user]="user"
                        [hideOnlineIndicator]="true"
                    ></app-user-avatar>
                </div>
            </ng-container>
            <ng-template #buttonsNotAuthorized>
                <a
                    id="lnk-login"
                    class="small nav-link"
                    color="primary"
                    mat-stroked-button
                    [routerLink]="['/auth/login']"
                    [queryParams]="{redirectTo: urlWithoutParams}"
                    rel="nofollow"
                >Login</a>
                <a
                    class="small nav-link"
                    id="lnk-join"
                    color="primary"
                    mat-flat-button
                    [routerLink]="['/auth/registration']"
                    rel="nofollow"
                >Join Today.<br>It's Free</a>
            </ng-template>
        </div>
    </nav>
</header>
